
.katex {
    font-family: inherit;
}

body, html, #root {
    height: 100%
}

.App {
    background-color: #292929;

    width: 100%;
    max-width: 600px;

    height: 100%;
    min-height: min-content;
    max-height: 700px;

    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 5px #000000A0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media screen and (min-height: 800px) {
    .App {
        margin-top: 5%;
    }
}

.Stack {
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.Expression {
    margin-left: 18px;
    margin-right: auto;
}

hr {
    background-color: #4e4e4e;
    width: 60%;
    height: 2px;
    border: 0;
}

.Input {
    background-color: #2B2B2B;
    color: #E1E1E1;
    min-height: 30px;
    padding: 8px 8px 8px 18px;
    font-size: 28px;
    line-height: 30px;
}

.Buttons {
    border-collapse: collapse;
    border-style: hidden;
    margin: 0 auto;
    width: 100%;
}

table.Buttons {
    table-layout : fixed;
}
table.Buttons td {
    border: 1px solid;
    background-color: #2D2D2D;
}

table.Buttons td.Num {
    border: 1px solid;
    background-color: #313131;
}

table.Buttons td:hover {
    background-color: #353535;
}


.Button {
    color: #cbcbcb;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 4px #FFFFFF20;
}

